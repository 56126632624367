import React, { ComponentProps } from "react";
import { Tag } from "@chakra-ui/react";

export const TopTag = ({ children, ...props }: ComponentProps<typeof Tag>) => (
  <Tag
    flex={1}
    size="md"
    borderRadius="full"
    bg="gray.500"
    fontSize="sm"
    textTransform="uppercase"
    textShadow="0 1px 0 black"
    border="1px solid #FFFFFF"
    color="white"
    alignItems="center"
    lineHeight={1}
    py={0}
    fontWeight="bold"
    {...props}
  >
    {children}
  </Tag>
);
