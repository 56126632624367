import React, { ComponentProps } from "react";
import { AnimatedLootBox } from "./AnimatedLootBox";
import { LootBoxWithClicks } from "./WithRequirements/LootBoxWithClicks";
import LootboxWithAd from "./WithRequirements/LootboxWithAd";
import { boxOpen } from "../../data/stats";
import { openLootbox } from "./LootBox";

export const LootboxSwitcher = ({
  lootbox,
  stage,
  setStage,
  ...props
}: ComponentProps<typeof AnimatedLootBox> & {
  lootbox: Lootbox;
  stage: number;
  setStage: (stage: number) => void;
}) => {
  const open = openLootbox(() => setStage(1), lootbox.rarity);

  if (stage > 0) {
    return (
      <AnimatedLootBox
        coins={lootbox.rewards.coins}
        stars={lootbox.rewards.stars}
        _key={lootbox.rewards.key}
        onOpen={() => {
          boxOpen(lootbox.requirements.clicks);
          setStage(2);
        }}
        open
        {...props}
      ></AnimatedLootBox>
    );
  }

  if (lootbox.requirements.ad) {
    return <LootboxWithAd onFulfilled={open} {...props}></LootboxWithAd>;
  }

  if (lootbox.requirements.clicks > 1) {
    return (
      <LootBoxWithClicks
        onFulfilled={open}
        clickRequirement={lootbox.requirements.clicks}
        {...props}
      ></LootBoxWithClicks>
    );
  }

  return (
    <AnimatedLootBox
      cursor="pointer"
      onClick={open}
      {...props}
    ></AnimatedLootBox>
  );
};
