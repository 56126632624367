import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import Alert from "../../Alert/Alert";
import { useGameData } from "../../../data/GameData";
import { useTranslation } from "react-i18next";
import SelectedLootbox from "./SelectedLootbox";
import LootboxChoice from "./LootboxChoice";
import { buttonClickSound } from "../../GameButton/GameButton";

const ChooseLootboxStage = ({ stage }: { stage: ChooseLootboxStage }) => {
  const { t } = useTranslation();
  const { lootboxes } = stage;
  const [selectedLootbox, setSelectedLootbox] = React.useState<
    Lootbox | undefined
  >();
  const {
    gameData: { coins },
    setGameData,
  } = useGameData();

  const incrementGameStage = () => {
    setGameData((gameData) => ({
      ...gameData,
      currentStage: gameData.currentStage + 1,
    }));
  };

  if (selectedLootbox && selectedLootbox.skip) {
    return (
      <Alert onNext={incrementGameStage} bg="red.600">
        {t("chooseLootbox.offer")}
      </Alert>
    );
  }

  if (selectedLootbox) {
    return <SelectedLootbox lootbox={selectedLootbox} />;
  }

  return (
    <VStack h="full">
      <Heading fontSize="2xl" textAlign="center" mt={5} mb={10}>
        {t("chooseLootbox.header")}
      </Heading>
      <SimpleGrid columns={2} h="full" w="full" gap={3}>
        {lootboxes.map((lootbox, i) => (
          <LootboxChoice
            key={i}
            onClick={() => {
              buttonClickSound.play();
              setSelectedLootbox(lootbox);
            }}
            lootbox={lootbox}
            coins={coins}
          />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default ChooseLootboxStage;
