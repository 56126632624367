import React from "react";
import TopBar from "../TopBar/TopBar";
import GameButton from "../GameButton/GameButton";
import { StoreContext } from "../Game";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { useGameData } from "../../data/GameData";

import JSConfetti from "js-confetti";
import { StoreOption } from "./StoreOption";
import { StoreForm } from "./StoreForm";

const jsConfetti = new JSConfetti();

export type Item = {
  cost: number;
  title: string;
  description: string;
  orderDetails: string;
  webhook?: string;
};

// https://webhook.site/#!/259a0991-7278-4d94-961c-ea5b768c3d0f/
const items = [
  {
    cost: 290,
    title: "Meer spelen!",
    description: "Uitnodiging voor onze eerstvolgende serious-game speltest.",
    orderDetails: "Meer info volgt op de mail!",
    webhook: "https://hooks.zapier.com/hooks/catch/7094682/39xoiju/",
  },
  {
    cost: 375,
    title: "Play for good!",
    description: "Leer inclusief designen. Je ontvangt de Print & Play versie van Play for Good.",
    orderDetails: "Voor een fysiek en mentaal veilige bedrijfsomgeving",
    webhook: "https://hooks.zapier.com/hooks/catch/7094682/39xoiju/",
  },
  {
    cost: 2499,
    title: "Brainstorm",
    description: "Samen met een collega kom je naar ons kantoor voor een serious game brainstorm.",
    orderDetails: "Brainstorm met jouw collega en een van onze serious game experts over een eigen serious game. ",
    webhook: "https://hooks.zapier.com/hooks/catch/7094682/39xoiju/",
  },
];

const Store = () => {
  const { setStoreActive } = React.useContext(StoreContext);
  const { setGameData } = useGameData();
  const [item, setItem] = React.useState<Item>();

  const subtractCoins = (amount: number) => {
    setGameData((prev) => {
      return {
        ...prev,
        coins: prev.coins - amount,
      };
    });
  };

  return (
    <Flex
      flexDir="column"
      w="full"
      h="full"
      pos="absolute"
      background="radial-gradient(closest-side at 50% 50%, #FFC665 0%, #8F764D 100%) 0% 0% no-repeat padding-box"
      left={0}
      top={0}
      zIndex={1000}
    >
      <TopBar pos="relative"></TopBar>
      {item ? (
        <StoreForm
          item={item}
          onBack={() => setItem(undefined)}
          onSuccess={() => {
            subtractCoins(item.cost);
            setItem(undefined);
          }}
        />
      ) : (
        <>
          <SimpleGrid flex={1} p={5} w="full" columns={2} gap={2}>
            <StoreOption
              cost={50}
              title="Confetti"
              description="Voor een kortdurend feestgevoel"
              onBuy={() => {
                subtractCoins(50);
                jsConfetti.addConfetti();
                jsConfetti.addConfetti({
                  emojis: ["💰", "⭐"],
                  emojiSize: 100,
                });
              }}
            ></StoreOption>

            {items.map((item) => (
              <StoreOption
                onBuy={() => setItem(item)}
                key={item.title}
                {...item}
              />
            ))}
          </SimpleGrid>

          <Box p={5}>
            <GameButton
              onClick={() => {
                setStoreActive(false);
              }}
            >
              Terug
            </GameButton>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default Store;
