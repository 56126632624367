export const stages: Stage[] = [
  { type: "tutorial" },
  //
  //
  //  EERSTE LOOTBOX KEUZE
  //
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 3,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.effort.false",
        },
      },
      {
        rarity: "basic",
        rewards: {
          coins: 25,
          stars: 3,
        },
        requirements: {
          clicks: 10,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.effort.true",
        },
        tag: "chooseLootbox.moreStars",
      },
    ],
  },
  //
  //
  //  TWEEDE LOOTBOX KEUZE
  //
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 3,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.visuals.false",
        },
      },
      {
        rarity: "epic",
        rewards: {
          coins: 50,
          stars: 3,
        },
        requirements: {
          clicks: 10,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.visuals.true",
        },
      },
    ],
  },
  //
  //
  //  DERDE LOOTBOX KEUZE
  //  Wordt overgeslagen als je de normale lootbox kiest
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 0,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
        skip: true,
      },
      {
        rarity: "epic",
        rewards: {
          coins: 125,
          stars: 3,
        },
        requirements: {
          clicks: 100,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.offer.0",
        },
      },
    ],
  },
  //
  //
  //  VIERDE LOOTBOX KEUZE
  //  met special offer
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 0,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
      },
      {
        rarity: "epic",
        rewards: {
          coins: 125,
          stars: 3,
        },
        requirements: {
          clicks: 25,
          ad: false,
          impossibleCoins: false,
        },
        popup: {
          text: "explanation.offer.1",
        },
        specialOffer: {
          clicks: 100,
        },
        tag: "chooseLootbox.specialOffer",
      },
    ],
  },
  //
  //
  //  VIJFDE LOOTBOX KEUZE
  //  met ad, je krijgt special key
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 0,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
        // popup: {
        //   text: "explanation.rare.false",
        // },
      },
      {
        rarity: "epic",
        rewards: {
          coins: 125,
          stars: 3,
          key: "key",
        },
        requirements: {
          clicks: 1,
          ad: true,
          impossibleCoins: true,
        },
        tag: "chooseLootbox.chanceForKey",
        // popup: {
        //   text: "explanation.rare.true",
        // },
      },
    ],
  },
  //
  //  ZESDE LOOTBOX KEUZE
  //  moet je met sleutel openen
  //  deze loopt door tot je level 5 bent
  //
  {
    type: "choose-lootbox",
    lootboxes: [
      {
        rarity: "basic",
        rewards: {
          coins: 5,
          stars: 1,
        },
        requirements: {
          clicks: 1,
          ad: false,
          impossibleCoins: false,
        },
        // popup: {
        //   text: "explanation.ad.false",
        // },
      },
      {
        rarity: "rare",
        rewards: {
          coins: 250,
          stars: 12,
        },
        requirements: {
          clicks: 50,
          impossibleCoins: false,
          ad: false,
          key: "key",
        },
        // popup: {
        //   text: "explanation.ad.true",
        // },
        tag: "chooseLootbox.level5",
      },
    ],
  },
  { type: "outro" },
];
