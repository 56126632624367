import React, { ComponentProps } from "react";
import { background, Box, Center, VStack } from "@chakra-ui/react";
import Overlay from "./Overlay";
import GameButton from "../GameButton/GameButton";
import { useTranslation } from "react-i18next";

const backgroundProps = Object.keys(background);

export const AlertBox = (props: ComponentProps<typeof Box>) => {
  // Extract background props
  const bgProps = Object.fromEntries(
    Object.entries(props).filter(([key]) => backgroundProps.includes(key))
  );
  // Remove background props from props
  props = Object.fromEntries(
    Object.entries(props).filter(([key]) => !backgroundProps.includes(key))
  );

  return (
    <Box
      _before={{
        content: "''",
        position: "absolute",
        inset: 0,
        bgColor: "gray.500",
        opacity: 0.8,
        zIndex: -1,
        ...bgProps,
      }}
      pos="relative"
      border="2px solid white"
      textAlign="center"
      px={2}
      py={8}
      w="full"
      lineHeight={1.15}
      {...props}
    ></Box>
  );
};

const Alert = ({
  onNext,
  ...props
}: ComponentProps<typeof Box> & {
  onNext?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Overlay>
      <VStack
        w="full"
        h="full"
        pos="absolute"
        top={0}
        left={0}
        p={5}
        zIndex={10}
        fontWeight={500}
        fontSize="xl"
        spacing={5}
      >
        <Center w="full" flex={1}>
          <AlertBox mt={20} {...props}></AlertBox>
        </Center>
        <GameButton onClick={onNext}>{t("buttons.continue")}</GameButton>
      </VStack>
    </Overlay>
  );
};

export default Alert;
