import React, { ComponentProps, useEffect } from "react";
import AnimatedLootBox from "../AnimatedLootBox";
import { Progress } from "@chakra-ui/react";
import { useAnimate } from "framer-motion";
const { Howl } = require("howler");

import chestClickOne from "../assets/check click 1.mp3";
import chestClickTwo from "../assets/check click 2.mp3";
import chestClickThree from "../assets/check click 3.mp3";

const audios = [
  new Howl({ src: [chestClickOne] }),
  new Howl({ src: [chestClickTwo] }),
  new Howl({ src: [chestClickThree] }),
];

function playRandomAudio() {
  const random = Math.floor(Math.random() * audios.length);
  const audio = audios[random];
  audio.play();
}

export const LootBoxWithClicks = ({
  clickRequirement = 1,
  onFulfilled,
  ...props
}: ComponentProps<typeof AnimatedLootBox> & {
  clickRequirement?: number;
  onFulfilled?: () => void;
}) => {
  const [clicks, setClicks] = React.useState(0);

  useEffect(() => {
    setClicks(0);
  }, [clickRequirement]);

  useEffect(() => {
    if (clicks === clickRequirement) {
      onFulfilled?.();
    }
  }, [clicks]);

  const [scope, animate] = useAnimate();

  const click = () => {
    const randomShake =
      (Math.random() * 10 + 5) * (Math.random() > 0.5 ? 1 : -1);
    setClicks((prev) => prev + 1);
    playRandomAudio();
    animate(
      scope.current,
      {
        x: [-randomShake, randomShake, -randomShake, randomShake, 0],
      },
      {
        duration: 0.4,
        ease: "linear",
      }
    );
  };

  return (
    <>
      <AnimatedLootBox
        onClick={click}
        cursor="pointer"
        ref={scope}
        {...props}
      ></AnimatedLootBox>
      <Progress
        value={(clicks / clickRequirement) * 100}
        left={5}
        right={5}
        bottom={5}
        pos="absolute"
        colorScheme="green"
        h={5}
        borderRadius={10}
        hasStripe
      ></Progress>
    </>
  );
};
