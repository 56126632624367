import { Box } from "@chakra-ui/react";
import React from "react";

export const Background = () => (
  <Box
    position="absolute"
    w="full"
    h="full"
    pointerEvents="none"
    zIndex={-99}
    id="background"
  >
    <Box
      position="absolute"
      w="full"
      h="full"
      bg={
        "url('vecteezy_six-sides-dice-faces-on-black-background-seamless-pattern_.png')"
      }
      mixBlendMode="screen"
      opacity={0.1}
    ></Box>
    <Box
      position="absolute"
      w="full"
      h="full"
      bgImg="radial-gradient(closest-side at 50% 50%, #34415000 0%, #344150 100%)"
    ></Box>
  </Box>
);
