export const stats = {
  time: new Date(0),
  boxes: 0,
  clicks: 0,
};

export function boxOpen(clicks = 1) {
  stats.boxes++;
  stats.clicks += clicks;
}

// @ts-ignore
window["stats"] = stats;
