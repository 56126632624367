import React from "react";
import { useGameData } from "../../data/GameData";
import { stats } from "../../data/stats";
import GameButton from "../GameButton/GameButton";
import { Item } from "./Store";
import {
  Box,
  FormControl,
  Input,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

export const StoreForm = (props: {
  item: Item;
  onBack: () => void;
  onSuccess: () => void;
}) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const {
    gameData: { coins },
  } = useGameData();

  const submit = () => {
    setEmail("");
    const data = {
      stats: {
        ...stats,
        time: undefined,
        startTime: stats.time,
        currentTime: new Date(),
      },
      item: props.item,
      coins,
      email,
    };
    delete data.stats.time;
    if (props.item.webhook) {
      setLoading(true);
      fetch(props.item.webhook, {
        method: "POST",
        body: JSON.stringify(data),
        mode:'cors',
      })
        .then((res) => {
          if (res.ok) {
            props.onSuccess();
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          setEmail(email);
          setError("Er is iets misgegaan, probeer het later opnieuw");
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  return (
    <VStack p={5} flexDir="column" h="full">
      <Heading>{props.item.title}</Heading>
      <Text>{props.item.orderDetails}</Text>
      <Text>Vul hieronder je e-mailadres in en we nemen contact met je op</Text>
      <FormControl isRequired>
        <Input
          onChange={(e) => {
            setEmail(e.target.value);
            setError(undefined);
          }}
          isDisabled={loading}
          type="email"
          placeholder="E-mailadres"
          value={email}
        ></Input>
      </FormControl>
      <GameButton
        onClick={submit}
        isDisabled={
          loading || !email || !email.includes("@") || !email.includes(".")
        }
      >
        Verstuur
      </GameButton>
      <Box flex={1}>{error}</Box>
      <GameButton onClick={props.onBack}>Terug</GameButton>
    </VStack>
  );
};
