import { Portal as ChakraPortal } from "@chakra-ui/react";
import React, { ComponentProps, RefObject } from "react";

export const PortalContext = React.createContext<
  RefObject<HTMLElement> | undefined
>(undefined);

const Overlay = ({
  children,
  ...props
}: ComponentProps<typeof ChakraPortal>) => {
  const ref = React.useContext(PortalContext);

  return (
    <ChakraPortal containerRef={ref} {...props}>
      {children}
    </ChakraPortal>
  );
};

export default Overlay;
