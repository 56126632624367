import { useMemo } from "react";
import { stages } from "../data/stages";

export const getStage = (stage: number) => {
  return useMemo(() => {
    return (
      stages[stage] ?? {
        type: "choose-lootbox",
        lootboxes: [
          {
            rarity: "basic",
            rewards: {
              coins: Math.ceil(Math.random() * 5),
              stars: 0,
            },
            requirements: {
              clicks: 1,
              ad: false,
            },
          },
          {
            rarity: Math.random() > 0.8 ? "epic" : "basic",
            rewards: {
              coins: Math.ceil(Math.random() * 36) + 14,
              stars: 3,
            },
            requirements: {
              clicks: Math.ceil(Math.random() * 21) + 4,
              ad: false,
            },
          },
        ],
      }
    );
  }, [stage]);
};
