import React, { ComponentProps, useEffect } from "react";
import { chakra, shouldForwardProp } from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";

import coinImg from "./assets/lootbox_coin@2x.png";
import starImg from "./assets/lootbox_star@2x.png";

const ChakraImg = chakra(motion.img, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const FlyingCoin = ({
  delay = 0,
  star = false,
  duration,
  ...props
}: ComponentProps<typeof ChakraImg> & {
  delay?: number;
  star?: boolean;
  duration?: number;
}) => {
  const [target, setTarget] = React.useState({
    x: star ? 0 : Math.random() * 40 - 20,
    y: 0,
  });
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const coinsCointer = document
      .getElementById(star ? "stars" : "coins")
      ?.getBoundingClientRect();
    const pos = ref.current?.getBoundingClientRect();

    const currentX = pos!.x + pos!.width / 2 + target.x;
    const currentY = pos!.y + pos!.height / 2;

    const targetX = coinsCointer!.left + coinsCointer!.width / 2;
    const targetY = coinsCointer!.top + coinsCointer!.height / 2;

    const dX = targetX - currentX;
    const dY = targetY - currentY;

    setTarget({
      x: dX,
      y: dY,
    });
  }, []);

  return (
    <ChakraImg
      ref={ref}
      w="20px"
      h="20px"
      position="absolute"
      src={star ? starImg : coinImg}
      // @ts-ignore
      transition={{
        duration: duration ?? star ? 0.4 : 0.2,
        delay,
      }}
      animate={{
        x: target.x,
        y: target.y,
      }}
      {...props}
    ></ChakraImg>
  );
};

export default FlyingCoin;
