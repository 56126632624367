import React, { ComponentProps } from "react";
import { Button } from "@chakra-ui/react";
const { Howl } = require("howler");

import buttonClick from "./button click.mp3";

export const buttonClickSound = new Howl({
  src: [buttonClick],
});

const GameButton = ({ onClick, ...props }: ComponentProps<typeof Button>) => {
  let defaultTheme: any = {
    colorScheme: "gray",
    bg: "gray.500",
    _hover: {
      bg: "gray.600",
    },
    _disabled: {
      bg: "gray.500",
      color: "rgb(255 255 255 / 50%)",
      cursor: "not-allowed",
    },
  };

  // if props contains any key in defaultTheme, then don't use defaultTheme
  if (Object.keys(props).some((key) => key in defaultTheme)) {
    defaultTheme = {};
  }

  return (
    <Button
      borderRadius="none"
      textTransform="uppercase"
      fontSize="xl"
      size="lg"
      w="full"
      border="2px solid white"
      onClick={(e) => {
        buttonClickSound.play();
        onClick?.(e);
      }}
      {...defaultTheme}
      {...props}
    ></Button>
  );
};

export default GameButton;
