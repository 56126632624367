import {
  Center,
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import React from "react";
import Game from "./components/Game";
import { GameDataProvider } from "./data/GameData";

const theme = extendTheme(
  {
    fonts: {
      heading: "Rubik, sans-serif",
      body: "Rubik, sans-serif",
    },
    styles: {
      global: {
        body: {
          background: "gray.700",
          color: "white",
          userSelect: "none",
        },
      },
    },
    colors: {
      basic: "#FFFFFF",
      rare: "#4D4DFF",
      epic: "#FF4D4D",
      legendary: "#FFD700",
    },
    components: {
      Button: {
        defaultProps: {
          colorScheme: "blue",
        },
      },
      Progress: {
        baseStyle: {
          filledTrack: {
            transitionProperty: "all",
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "blue" })
);

declare global {
  interface Window {
    setFullScreen: (fullScreen: boolean) => void;
  }
}

const App = () => {
  const [fullScreen, setFullScreen] = React.useState(true);
  window.setFullScreen = setFullScreen;

  return (
    <ChakraProvider theme={theme}>
      <GameDataProvider>
        <Center h="full" w="full">
          <Game
            w={fullScreen ? "full" : 320}
            h={fullScreen ? "full" : 568}
          ></Game>
        </Center>
      </GameDataProvider>
    </ChakraProvider>
  );
};

export default App;
