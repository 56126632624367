import React from "react";

interface GameData {
  coins: number;
  stars: number;
  level: number;
  currentStage: number;
  keys: string[];
}

const _gameData: GameData = {
  coins: 0,
  stars: 0,
  level: 1,
  keys: [],
  currentStage: 0,
};

export const starThreshold = (level: number): number => {
  if (level <= 2) return 3;
  return starThreshold(level - 1) + (level - 1) + 3;
};

type GameDataContextType = {
  gameData: GameData;
  setGameData: React.Dispatch<React.SetStateAction<GameData>>;
};

const GameDataContext = React.createContext<GameDataContextType>({
  gameData: _gameData,
  setGameData: () => {
    // do nothing
  },
});

export const GameDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [gameData, setGameData] = React.useState<GameData>(_gameData);
  // const { level, stars } = gameData;

  // React.useEffect(() => {
  //   if (stars >= starThreshold(level + 1)) {
  //     setGameData((prev) => ({
  //       ...prev,
  //       level: prev.level + 1,
  //     }));
  //   }
  // }, [stars]);

  return (
    <GameDataContext.Provider
      value={{
        gameData,
        setGameData,
      }}
    >
      {children}
    </GameDataContext.Provider>
  );
};

export const useGameData = () => {
  return React.useContext(GameDataContext);
};

export default GameDataContext;
