import { Center, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AnimatedLootBox from "../Lootbox/AnimatedLootBox";
import Alert from "../Alert/Alert";
import { useGameData } from "../../data/GameData";
import { LevelUpPopUp } from "../LevelUp/LevelUp";
import { boxOpen, stats } from "../../data/stats";
import { useTranslation } from "react-i18next";
import { openLootbox } from "../Lootbox/LootBox";

const Tutorial = () => {
  const [stage, setStage] = React.useState(0);
  const { setGameData } = useGameData();
  const { t } = useTranslation();

  const incrementStage = () => {
    boxOpen();
    setGameData((gameData) => ({
      ...gameData,
      currentStage: gameData.currentStage + 1,
    }));
  };

  const incrementLevel = () => {
    setGameData((gameData) => ({
      ...gameData,
      level: gameData.level + 1,
    }));
  };

  useEffect(() => {
    stats.time = new Date();
  }, []);

  const open = openLootbox(() => setStage(1));

  return (
    <VStack h="full" gap={4}>
      <Heading
        fontSize="2xl"
        textAlign="center"
        mt={6}
        fontWeight={600}
        px={4}
        visibility={stage === 0 ? "visible" : "hidden"}
      >
        {t("tutorial.lootbox")}
      </Heading>
      <Center flex={1}>
        {stage === 0 && (
          <AnimatedLootBox
            onClick={open}
            cursor="pointer"
            key="tut"
          ></AnimatedLootBox>
        )}
        {(stage === 1 || stage === 2) && (
          <AnimatedLootBox
            coins={5}
            open
            onOpen={() => setStage(2)}
            key="tut"
          ></AnimatedLootBox>
        )}
        {(stage === 3 || stage === 4 || stage === 5 || stage === 6) && (
          <AnimatedLootBox
            stars={3}
            key="tut"
            open
            onOpen={() => setStage(4)}
          ></AnimatedLootBox>
        )}
      </Center>
      {stage === 2 && (
        <Alert onNext={() => setStage(3)}>{t("tutorial.coins")}</Alert>
      )}
      {stage === 4 && (
        <LevelUpPopUp
          onNext={() => {
            incrementLevel();
            setStage(5);
          }}
        ></LevelUpPopUp>
      )}
      {stage === 5 && (
        <Alert onNext={() => setStage(6)}>{t("tutorial.stars")}</Alert>
      )}
      {stage === 6 && (
        <Alert onNext={() => incrementStage()}>{t("tutorial.goal")}</Alert>
      )}
    </VStack>
  );
};

export default Tutorial;
