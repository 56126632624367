import React, { ComponentProps, useEffect } from "react";
import {
  Center,
  chakra,
  forwardRef,
  Image,
  shouldForwardProp,
} from "@chakra-ui/react";
import FlyingCoin from "./Loot/FlyingCoin";
import { isValidMotionProp, motion, useAnimate } from "framer-motion";
import LootBox from "./LootBox";
import { useGameData } from "../../data/GameData";
import Alert from "../Alert/Alert";
import KeyImg from "./assets/rare-key.png";
import { useTranslation } from "react-i18next";
import coinWav from "./Loot/assets/collect_coin.wav";
import keyFound from "./assets/rare chest found.mp3";
import starWav from "./Loot/assets/Star Collect.mp3";
import entrance from "./assets/lootbox entrance.wav";
const { Howl, Howler } = require("howler");

const coinSound = new Howl({
  src: [coinWav],
  volume: 0.1,
});
const starSound = new Howl({
  src: [starWav],
  volume: 0.8,
});
const keyFoundHowl = new Howl({
  src: [keyFound],
});
const entranceHowl = new Howl({
  src: [entrance],
});

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

type Props = ComponentProps<typeof ChakraBox> &
  ComponentProps<typeof LootBox> & {
    coins?: number;
    stars?: number;
    _key?: string;
    onOpen?: () => void;
  };

export const AnimatedLootBox = forwardRef(
  (
    {
      open = false,
      rarity = "basic",
      coins = 0,
      stars = 0,
      _key: key,
      onOpen,
      ...props
    }: ComponentProps<typeof ChakraBox> & Props,
    ref
  ) => {
    const [receivedRewards, setRewards] = React.useState(0);
    const [animationComplete, setAnimationComplete] = React.useState(false);
    const [keyRef, animateKey] = useAnimate();
    const [keyAlert, setKeyAlert] = React.useState(false);
    const { setGameData } = useGameData();
    const { t } = useTranslation();

    useEffect(() => {
      setRewards(0);
    }, [coins, stars, key]);

    useEffect(() => {
      if (
        receivedRewards ===
        Math.sign(coins) + Math.sign(stars) + Math.sign(Number(!!key))
      ) {
        onOpen?.();
      }
    }, [receivedRewards]);

    useEffect(() => {
      if (receivedRewards === Math.sign(coins) + Math.sign(stars) && key) {
        keyFoundHowl.play();
        animateKey(keyRef.current, {
          y: -150,
          scale: 0.8,
        }).then(() => {
          setKeyAlert(true);
        });
      }
    }, [receivedRewards]);

    const addStar = () => {
      setGameData((gameData) => ({
        ...gameData,
        stars: gameData.stars + 1,
      }));
    };

    const addCoin = () => {
      setGameData((gameData) => ({
        ...gameData,
        coins: gameData.coins + 1,
      }));
    };

    const speed =
      coins <= 15 ? 0.1 : coins <= 50 ? 0.065 : coins <= 150 ? 0.03 : 0.015;

    return (
      <ChakraBox
        key="box"
        pos="relative"
        animate={{
          top: "0%",
        }}
        pointerEvents={animationComplete ? "auto" : "none"}
        // @ts-ignore
        transition={{
          duration: 0.8,
          bounce: 0.4,
          type: "spring",
          delay: 0.5,
        }}
        initial={open ? { top: "0%" } : { top: "-150%" }}
        willChange="top"
        ref={ref}
        onAnimationComplete={() => {
          setAnimationComplete(true);
        }}
        onAnimationStart={() => {
          setTimeout(() => {
            if (Howler._audioUnlocked) entranceHowl.play();
          }, 500);
        }}
        px={5}
        {...props}
      >
        <LootBox open={open} rarity={rarity}></LootBox>
        {open && (
          <Center inset={0} pos="absolute">
            {Array(coins)
              .fill(0)
              .map((_, i) => (
                <FlyingCoin
                  onAnimationComplete={(def) => {
                    //  @ts-ignore
                    if (Math.abs(def.x) > 20 && Math.abs(def.y) > 20) {
                      addCoin();
                      coinSound.play();
                      if (i === coins - 1) {
                        setRewards((prev) => prev + 1);
                      }
                    }
                  }}
                  delay={i * speed + 0.2}
                  key={i}
                ></FlyingCoin>
              ))}
            {Array(stars)
              .fill(0)
              .map((_, i) => (
                <FlyingCoin
                  star
                  onAnimationComplete={(def) => {
                    //  @ts-ignore
                    if (Math.abs(def.x) > 20 && Math.abs(def.y) > 20) {
                      addStar();
                      starSound.play();
                      if (i === stars - 1) {
                        setRewards((prev) => prev + 1);
                      }
                    }
                  }}
                  delay={i * 0.2 + 0.4}
                  key={i}
                ></FlyingCoin>
              ))}
            {key && (
              <ChakraBox
                initial={{
                  rotate: "30deg",
                  scale: 0.5,
                }}
                ref={keyRef}
                pos="absolute"
              >
                <Image src={KeyImg}></Image>
              </ChakraBox>
            )}
          </Center>
        )}
        {keyAlert && (
          <Alert
            alignSelf="flex-end"
            onNext={() => {
              setKeyAlert(false);
              if (key)
                setGameData((gameData) => ({
                  ...gameData,
                  keys: [...gameData.keys, key],
                }));
              setRewards((prev) => prev + 1);
            }}
          >
            {t("explanation.rareKey")}
          </Alert>
        )}
      </ChakraBox>
    );
  }
);

export default AnimatedLootBox;
