import { starThreshold, useGameData } from "../../../data/GameData";
import Yellow from "../../Yellow";
import { LootboxSwitcher } from "../../Lootbox/LootboxSwitcher";
import { LevelUpPopUp } from "../../LevelUp/LevelUp";
import Alert from "../../Alert/Alert";
import { useEffect } from "react";
import { Center, Heading, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React from "react";

const SelectedLootbox = ({ lootbox }: { lootbox: Lootbox }) => {
  const [stage, setStage] = React.useState(0);
  const { t } = useTranslation();
  const {
    gameData: { level, stars },
    setGameData,
  } = useGameData();
  const [levelUp, setLevelUp] = React.useState(false);

  const incrementLevel = () => {
    setGameData((gameData) => {
      let currLevel = gameData.level;
      while (stars >= starThreshold(currLevel + 1)) {
        currLevel++;
      }

      return {
        ...gameData,
        level: currLevel,
      };
    });
  };

  const incrementGameStage = () => {
    setGameData((gameData) => ({
      ...gameData,
      currentStage: gameData.currentStage + 1,
    }));
  };

  useEffect(() => {
    if (stars >= starThreshold(level + 1)) {
      setLevelUp(true);
    }
  }, [level, stars]);

  return (
    <VStack h="full">
      <Heading
        fontSize="xl"
        textAlign="center"
        px={4}
        mt={6}
        fontWeight={600}
        visibility={stage > 0 ? "hidden" : "visible"}
      >
        <Yellow>
          {t("requirements.clicks.0", {
            clicks: lootbox.requirements.clicks,
          })}
        </Yellow>{" "}
        {t("requirements.clicks.1", { rarity: lootbox.rarity })}
      </Heading>
      <Center flex={1}>
        <LootboxSwitcher
          stage={stage}
          setStage={setStage}
          rarity={lootbox.rarity}
          lootbox={lootbox}
        ></LootboxSwitcher>
      </Center>

      {stage === 2 &&
        (levelUp ? (
          <LevelUpPopUp
            onNext={() => {
              incrementLevel();
              setLevelUp(false);
              if (!lootbox.popup) {
                incrementGameStage();
              }
            }}
          ></LevelUpPopUp>
        ) : lootbox.popup ? (
          <Alert
            bg={lootbox.popup.background ?? "yellow.500"}
            onNext={incrementGameStage}
          >
            {t(lootbox.popup.text)}
          </Alert>
        ) : (
          <Alert display="none" onNext={incrementGameStage}></Alert>
        ))}
    </VStack>
  );
};

export default SelectedLootbox;
