import React from "react";
import { Box } from "@chakra-ui/react";
import TopBar from "./TopBar/TopBar";
import { Background } from "./Game/Background";
import { useGameData } from "../data/GameData";
import Tutorial from "./Game/Tutorial";
import { PortalContext } from "./Alert/Overlay";
import ChooseLootboxStage from "./Game/ChooseLootbox/ChooseLootboxStage";
import { getStage } from "../util/randomStage";
import Outro from "./Game/Outro";
import Alert from "./Alert/Alert";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Store from "./Store/Store";

const DesktopWarning = () => {
  const [show, setShow] = React.useState(true);
  const { t } = useTranslation();

  if (isDesktop && show)
    return (
      <Alert bgColor="red.600" onNext={() => setShow(false)}>
        <b>{t("desktopWarning.0")}</b> {t("desktopWarning.1")}
      </Alert>
    );

  return null;
};

function GameWindow() {
  const { gameData } = useGameData();
  const { currentStage } = gameData;
  const stage = getStage(currentStage);

  return (
    <React.Fragment key={currentStage}>
      <Background />
      <TopBar></TopBar>
      <Box pos="relative" w="full" h="full" p={5} pt="60px">
        {stage?.type === "outro" && <Outro></Outro>}
        {stage?.type === "tutorial" && <Tutorial></Tutorial>}
        {stage?.type === "choose-lootbox" && (
          <ChooseLootboxStage stage={stage}></ChooseLootboxStage>
        )}
      </Box>
    </React.Fragment>
  );
}

export const StoreContext = React.createContext<{
  setStoreActive: (active: boolean) => void;
}>({
  setStoreActive: () => undefined,
});

const Game = (props: React.ComponentProps<typeof Box>) => {
  const [storeActive, setStoreActive] = React.useState<boolean>(false);
  const portalRef = React.useRef<HTMLDivElement>(null);

  return (
    <PortalContext.Provider value={portalRef}>
      <DesktopWarning></DesktopWarning>
      <StoreContext.Provider
        value={{
          setStoreActive,
        }}
      >
        <Box
          id="game"
          position="relative"
          overflow="hidden"
          ref={portalRef}
          {...props}
        >
          <GameWindow />
          {storeActive && <Store />}
        </Box>
      </StoreContext.Provider>
    </PortalContext.Provider>
  );
};

export default Game;
