import React, { ComponentProps, useEffect } from "react";
import { Center, Image, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
const { Howl } = require("howler");
import debounce from "lodash/debounce";
import Overlay from "../Alert/Overlay";
import GameButton from "../GameButton/GameButton";
import { useTranslation } from "react-i18next";

import levelUpSound from "./level up.mp3";

const levelUpHowl = new Howl({
  src: [levelUpSound],
  volume: 1,
});

const playLevelUp = debounce(levelUpHowl.play.bind(levelUpHowl), 100);

const LevelUp = (props: ComponentProps<typeof Image>) => {
  useEffect(() => {
    playLevelUp();
  }, []);

  return (
    <Image
      as={motion.img}
      src="lootbox_level_up@2x.png"
      pos="absolute"
      p={4}
      zIndex={100}
      {...props}
      animate={{
        scale: [0, 1],
        transition: {
          delay: 0.2,
          duration: 0.6,
          type: "spring",
          bounce: 0.6,
        },
      }}
    ></Image>
  );
};

export default LevelUp;

export const LevelUpPopUp = ({ onNext }: { onNext?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Overlay>
      <VStack
        w="full"
        h="full"
        pos="absolute"
        top={0}
        left={0}
        p={5}
        zIndex={10}
        fontWeight={500}
        fontSize="xl"
        spacing={5}
      >
        <Center w="full" flex={1}>
          <LevelUp></LevelUp>
        </Center>
        <GameButton onClick={onNext}>{t("buttons.continue")}</GameButton>
      </VStack>
    </Overlay>
  );
};
