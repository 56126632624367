import React, { ComponentProps } from "react";
import { Box, Image } from "@chakra-ui/react";

const Howl = require("howler").Howl;
import debounce from "lodash/debounce";

import normalLootboxOpen from "./assets/open chest normal.mp3";
import rareLootboxOpen from "./assets/open chest sparkle.mp3";

const normalLootboxSound = new Howl({
  src: [normalLootboxOpen],
});

const rareLootboxSound = new Howl({
  src: [rareLootboxOpen],
});

export function openLootbox(fn: () => void, rarity = "basic") {
  return debounce(
    () => {
      if (rarity === "basic") {
        normalLootboxSound.play();
      } else {
        rareLootboxSound.play();
      }
      setTimeout(fn, 900);
    },
    1000,
    {
      leading: true,
      trailing: false,
    }
  );
}

// import all images in assets directory
// @ts-ignore
const requireAll = require.context("./assets", false);
const images = Object.fromEntries(
  requireAll.keys().map((key: string) => {
    return [key, requireAll(key)];
  })
);

const LootBox = ({
  open = false,
  rarity = "basic",
  ...props
}: ComponentProps<typeof Box> & {
  open?: boolean;
  rarity?: Lootbox["rarity"];
}) => {
  return (
    <Box pos="relative" {...props}>
      {open ? (
        <>
          <Image src={images[`./${rarity}-open.png`]} pos="absolute"></Image>
          <Image
            src={images[`./${rarity}-open-front.png`]}
            pos="relative"
            zIndex={1}
          ></Image>
        </>
      ) : (
        <Image src={images[`./${rarity}-closed.png`]}></Image>
      )}
    </Box>
  );
};

export default LootBox;
