import React from "react";
import { useGameData } from "../../../data/GameData";
import LootBox from "../../Lootbox/LootBox";
import GameButton from "../../GameButton/GameButton";
import { useTranslation } from "react-i18next";
import { Box, Heading, VStack, Text } from "@chakra-ui/react";

const Button = ({
  lootbox,
  noKey,
  ...props
}: {
  lootbox: Lootbox;
  noKey?: boolean;
} & React.ComponentProps<typeof GameButton>) => {
  const { t } = useTranslation();

  if (lootbox.requirements.ad) {
    return (
      <Box>
        <GameButton fontSize="sm" h="50%" {...props} isDisabled>
          coins
        </GameButton>
        <GameButton
          fontSize="sm"
          h="50%"
          borderTopWidth={0}
          transform="translateY(-1px)"
          {...props}
        >
          {t("chooseLootbox.viewAd")}
        </GameButton>
      </Box>
    );
  }

  return (
    <GameButton fontSize="sm" whiteSpace="pre-line" {...props}>
      {noKey === true ? (
        <>
          sleutel
          <br />
          nodig
        </>
      ) : (
        t("chooseLootbox.choose")
      )}
    </GameButton>
  );
};

const LootboxChoice = ({
  coins,
  lootbox,
  onClick,
}: {
  onClick: () => void;
  lootbox: Lootbox;
  coins: number;
}) => {
  const { t } = useTranslation();
  const [hover, setHover] = React.useState(false);
  const {
    gameData: { keys },
  } = useGameData();

  const noKey = lootbox.requirements.key
    ? !keys.includes(lootbox.requirements.key)
    : false;

  const selectable = {
    onClick: noKey ? undefined : onClick,
    cursor: noKey ? undefined : "pointer",
  };

  return (
    <VStack spacing={0} pos="relative">
      <VStack
        w="full"
        p={4}
        flex={1}
        textAlign="center"
        border="2px solid white"
        borderBottomWidth={0}
        justifyContent="space-evenly"
        pos="relative"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...selectable}
      >
        <Heading fontSize="lg" as="h3" textTransform="uppercase">
          {lootbox.rarity} lootbox
        </Heading>
        <LootBox
          filter="drop-shadow(0px 0px 10px #ffffff)"
          rarity={lootbox.rarity}
        ></LootBox>
        {lootbox.requirements.impossibleCoins ? (
          <Text lineHeight={1.25} fontWeight={500}>
            {coins + 15} coins of bekijk een ad (10s)
          </Text>
        ) : (
          <Text lineHeight={1.25} fontWeight={500}>
            <Text
              textDecoration="line-through"
              textDecorationColor="red.600"
              textDecorationThickness="3px"
              as="span"
            >
              {lootbox.specialOffer?.clicks}
            </Text>{" "}
            {lootbox.requirements.clicks} {t("chooseLootbox.clicks")}
          </Text>
        )}
      </VStack>
      <Button
        data-hover={hover || undefined}
        isDisabled={noKey}
        lootbox={lootbox}
        noKey={noKey}
        {...selectable}
      ></Button>
      {!!lootbox.tag && (
        <Box
          pos="absolute"
          border="2px solid white"
          bg="red.600"
          bottom="100%"
          left={0}
          right={0}
          textTransform="uppercase"
          fontSize="xs"
          fontWeight={600}
          textAlign="center"
          transform="translateY(2px)"
        >
          {t(lootbox.tag)}
        </Box>
      )}
    </VStack>
  );
};

export default LootboxChoice;
