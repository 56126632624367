import React from "react";
import { Grid, Image, TagLabel } from "@chakra-ui/react";
import { TopTag } from "./TopTag";
import { starThreshold, useGameData } from "../../data/GameData";

import coinImg from "../Lootbox/Loot/assets/lootbox_coin@2x.png";
import starImg from "../Lootbox/Loot/assets/lootbox_star@2x.png";
import { StoreContext } from "../Game";

const TopBar = (props: React.ComponentProps<typeof Grid>) => {
  const { gameData } = useGameData();
  const { setStoreActive } = React.useContext(StoreContext);
  const { coins, stars, level } = gameData;

  return (
    <Grid
      templateColumns="auto auto auto"
      gap={2}
      py={4}
      px={6}
      position="absolute"
      w="full"
      top={0}
      zIndex={11}
      {...props}
    >
      <TopTag
        mr={1}
        id="coins"
        cursor="pointer"
        onClick={() => {
          setStoreActive(true);
        }}
      >
        <Image
          src={coinImg}
          w={5}
          h={5}
          position="relative"
          top={0}
          left={-3}
          transform="scale(1.5)"
        ></Image>
        <TagLabel pl={0} lineHeight={1}>
          {coins}
        </TagLabel>
      </TopTag>

      <TopTag textAlign="center">
        <TagLabel pl={0} lineHeight={1} w="full">
          LEVEL {level}
          {level <= 5 && "/5"}
        </TagLabel>
      </TopTag>

      <TopTag ml={3} mr={-3} id="stars">
        <Image
          src={starImg}
          w={5}
          h={5}
          position="relative"
          top={0}
          left={-4}
          transform="scale(1.7)"
        ></Image>
        <TagLabel pl={0} lineHeight={1}>
          {stars}/{starThreshold(level + 1)}
        </TagLabel>
      </TopTag>
    </Grid>
  );
};

export default TopBar;
