import React from "react";
import { useGameData } from "../../data/GameData";
import coinsImg from "./coins stack.png";
import GameButton from "../GameButton/GameButton";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

export const StoreOption = (props: {
  cost: number;
  title: string;
  description: string;
  onBuy?: () => void;
}) => {
  const {
    gameData: { coins },
  } = useGameData();

  return (
    <Flex flexDir="column" lineHeight={1}>
      <Heading
        size="md"
        color="black"
        bg="white"
        borderX="2px solid white"
        textAlign="center"
        p={1}
        lineHeight={1}
        whiteSpace="nowrap"
      >
        <Image
          draggable={false}
          src={coinsImg}
          display="inline"
          h="50px"
          my="-18px"
          mr={2}
          ml={-3}
        />
        {props.cost}x
      </Heading>
      <Box
        flex={1}
        bg="whiteAlpha.400"
        borderX="2px solid white"
        p={2}
        color="black"
      >
        <Heading size="sm" as="h3" mb={2}>
          {props.title}
        </Heading>
        <Text fontSize="sm">{props.description}</Text>
      </Box>
      <GameButton
        size="md"
        fontSize="md"
        isDisabled={coins < props.cost}
        onClick={props.onBuy}
      >
        kopen
      </GameButton>
    </Flex>
  );
};
