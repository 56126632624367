import { Center, Heading, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import GameButton from "../GameButton/GameButton";
import { useGameData } from "../../data/GameData";
import Yellow from "../Yellow";
import { stats } from "../../data/stats";
import { stages } from "../../data/stages";
import { useTranslation } from "react-i18next";

function timeDifferenceInMinutes(date1: Date, date2: Date) {
  const diff = date1.getTime() - date2.getTime();
  return Math.floor(diff / 1000 / 60);
}

const textStages: Record<string, (() => JSX.Element)[]> = {
  "nl-NL": [
    () => (
      <>
        Gefeliciteerd, je bent level 5!
        <br />
        <br />
        Je in het totaal <Yellow>{stats.boxes} lootboxes</Yellow> geopent. Daar
        heb je{" "}
        <Yellow>
          {timeDifferenceInMinutes(new Date(), stats.time)} minuten
        </Yellow>{" "}
        over gedaan en <Yellow>{stats.clicks} keer voor geklikt</Yellow>! En
        waarvoor eigenlijk...?
        <br />
        <br /> Had je deze investering van tevoren verwacht van jezelf?
      </>
    ),
    () => (
      <>
        <Yellow>Lootboxes</Yellow> kunnen er voor zorgen dat je meer investeert
        dan je wilt. In dit spel was dat vooral <Yellow>tijd</Yellow>, maar vaak
        gaat het om geld.
        <br />
        Je hebt <Yellow>5 verleidings&shy;technieken</Yellow> gezien, kijk snel
        verder..
      </>
    ),
    () => (
      <>
        Techniek 1
        <br /> <br />
        Door ergens moeite voor te moeten doen, wordt de{" "}
        <Yellow>gevoelswaarde</Yellow> van de beloning hoger.
      </>
    ),
    () => (
      <>
        Techniek 2
        <br /> <br />
        Door slimme <Yellow>visuals en geluiden</Yellow> te gebruiken, kun je de
        gevoelswaarde, maar ook je winst-ervaring vergroten. Dit zijn trucjes
        die het <Yellow>casino</Yellow> ook gebruikt.
      </>
    ),
    () => (
      <>
        Techniek 3
        <br /> <br />
        Door <Yellow>onweerstaanbare</Yellow> aanbiedingen te geven met flinke
        korting, die tijdelijk zijn waardoor je snel moet beslissen, ben je
        geneigd daar voor te kiezen.
      </>
    ),
    () => (
      <>
        Techniek 4
        <br /> <br />
        De kans om iets unieks te krijgen, iets <Yellow>zeldzaams</Yellow>, met
        een actie uniek voor jou, maak je het heel{" "}
        <Yellow>aantrekkelijk</Yellow> om er op in te gaan. Zeker als je graag
        wilt verzamelen, of opscheppen belangrijk vind, werkt dit goed.
      </>
    ),
    () => (
      <>
        Techniek 5
        <br /> <br />
        Als je eenmaal <Yellow>tijd of geld</Yellow> hebt geïnvesteerd, kost het
        meer moeite om te stoppen. Anders was het zonde van al die tijd. Daarom
        ga je toch maar weer even door.
      </>
    ),
    () => (
      <>
        Nu weet je waarom <Yellow>lootboxes</Yellow> goed werken <br /> <br />
        Veel mensen zijn hier gevoelig voor. <br /> <br />
        Het is net een soort gokken! <br /> <br />
        Wil je verder spelen? Klik dan op <Yellow>volgende</Yellow>. Anders kun
        je de app afsluiten. Bedankt voor het spelen!
      </>
    ),
  ],
};

textStages.nl = textStages["nl-NL"];

const getStage = (lang: string, stage: number) =>
  textStages[lang]?.[stage]?.() ??
  textStages["nl"]?.[stage]?.() ?? (
    <>
      {lang} {stage}
    </>
  );

const Outro = () => {
  const { setGameData, gameData } = useGameData();
  const { t, i18n } = useTranslation();
  const [stage, setStage] = React.useState(0);

  const handleNext = () => {
    if (stage < textStages["nl"].length - 1) {
      setStage((prev) => prev + 1);
    } else {
      setGameData((prev) => {
        return {
          ...prev,
          currentStage: prev.currentStage + 1,
        };
      });
    }
  };

  useEffect(() => {
    if (gameData.level < 5) {
      // decrement stage
      setGameData((prev) => {
        return {
          ...prev,
          currentStage: stages.length - 3,
        };
      });
    }
  }, []);

  return (
    <VStack h="full" gap={4}>
      <Center flex={1} w="full">
        <Heading textAlign="center" as="h2" fontSize="xl" w="80%">
          {getStage(i18n.language, stage)}
        </Heading>
      </Center>
      <GameButton onClick={handleNext}>{t("buttons.continue")}</GameButton>
    </VStack>
  );
};

export default Outro;
