import React from "react";
import Overlay from "../../Alert/Overlay";
import AnimatedLootBox from "../AnimatedLootBox";

const LootboxWithAd = ({
  onFulfilled,
  ...props
}: {
  onFulfilled?: () => void;
}) => {
  const [watchedAd, setWatchedAd] = React.useState(false);

  if (watchedAd) {
    return (
      <AnimatedLootBox
        cursor="pointer"
        onClick={onFulfilled}
        {...props}
      ></AnimatedLootBox>
    );
  }

  return (
    <Overlay>
      <video
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          zIndex: 100,
        }}
        src="https://player.vimeo.com/progressive_redirect/playback/843873760/rendition/240p/file.mp4?loc=external&log_user=0&signature=8a63df2b6371c20804b12e11e36f1f5ef10542690233f8177525fc32eea6a4a4"
        autoPlay
        onEnded={() => {
          setWatchedAd(true);
        }}
      ></video>
    </Overlay>
  );
};

export default LootboxWithAd;
